import { PAYMENT_TYPE } from '@mkt-client/subscription';
import { AOFL_PRODUCT_UUIDS, PLATFORMS, LOCAL_STORAGE_KEYS } from '$lib/utils/constants';
import { getDeviceId } from '$lib/utils/device-id';
import { browser } from '$app/environment';
import * as Sentry from '@sentry/browser';
import APIError from './error';

/** @type {Function} */
let fetchFunc;
/**
 * @type {new (...args: any[]) => Headers} HeadersConstructor
 */
let HeadersFunc;
/**
 * @type {new (...args: any[]) => FormData} HeadersConstructor
 */
let FormDataFunc;

(async function () {
	if (browser) {
		fetchFunc = window.fetch;
		HeadersFunc = window.Headers;
		FormDataFunc = window.FormData;
	} else {
		// TODO: fix this to work in docker
		// const fetch = await import('node-fetch');
		// const { Headers } = await import('node-fetch');
		// const FormData = await import('form-data');
		// fetchFunc = fetch.default;
		// // @ts-ignore
		// HeadersFunc = Headers;
		// // @ts-ignore
		// FormDataFunc = FormData.default;
	}
})();

/**
 * In-memory storage object used as a fallback when localStorage is not available.
 * This is particularly useful in non-browser environments or when localStorage access is restricted.
 * @type {Record.<string, string>}
 */
let inMemoryStorage = {};

const getLocalStorageItem = (/** @type {string} */ key) => {
	if (browser) {
		return localStorage.getItem(key);
	} else {
		return inMemoryStorage[key] || null;
	}
};

const setLocalStorageItem = (/** @type {string} */ key, /** @type {string} */ value) => {
	if (browser) {
		localStorage.setItem(key, value);
	} else {
		inMemoryStorage[key] = value;
	}
};

class ApiService {
	static BASE_URL = '';
	/**
	 * Sends a request to the API endpoint.
	 * @param {string} url - url path
	 * @param {object} payload - The payload to send with the request.
	 * @param {Function} fetchFunction - The fetch function to use (dependency injection for testing/non-browser env).
	 * @returns {Promise<object>} - A promise that resolves to the response from the API.
	 */
	static async request(url, payload, fetchFunction = fetchFunc) {
		// TODO: fix this before deployment
		url = ApiService.BASE_URL + url;

		Sentry.setTag('api_url', url);
		const requestPromise = await fetchFunction(url, ApiService.pack(payload));
		return ApiService.unpack(requestPromise);
	}

	/**
	 * Packs the payload into a format suitable for making a POST request.
	 * @param {object} payload - The payload to be packed.
	 * @returns {object} - The packed request object.
	 */
	static pack(payload) {
		const headers = new HeadersFunc();
		const body = new FormDataFunc();

		if (payload) body.append('arguments', JSON.stringify(payload));
		body.append('platform', PLATFORMS.DEFAULT);
		body.append('device_id', getDeviceId());
		body.append('aofl_product_uuid', AOFL_PRODUCT_UUIDS.ABCMOUSE);

		const aoflToken = getLocalStorageItem(LOCAL_STORAGE_KEYS.AOFL_TOKEN);
		if (aoflToken !== null) {
			headers.append('Aofl-Token', aoflToken?.replace(/['"]+/g, ''));
		}

		Sentry.addBreadcrumb({
			category: 'api',
			message: 'API Pre-Request Payload',
			level: 'info',
			data: {
				payload
			}
		});

		return {
			method: 'POST',
			headers,
			body,
			mode: 'cors',
			credentials: 'include'
		};
	}

	/**
	 * Unpacks the response from an API call and handles any error conditions.
	 * @param {Response} response - The response object from the API call.
	 * @returns {Promise<object>} - The payload of the response if successful.
	 * @throws {object} - An error object containing the error code, message, and payload if the API call was not successful.
	 */
	static async unpack(response) {
		const json = await response.json();
		const aoflToken = response.headers.get('aofl-token');

		// TODO: add recaptcha service/handling
		let err = null;

		if (json.success !== 'TRUE') {
			const code = json.payload?.error?.code || 1;

      Sentry.setTag('error_code', code);
			const message = json.payload?.error?.message || 'unknown error.';
			const payload = json.payload?.error?.payload || {};
			err = new APIError(message, code, { payload });
		}


		if (err) {
			Sentry.addBreadcrumb({
				category: 'api',
				message: 'API Post-Request Error',
				level: 'error',
				data: {
					err
				}
			});
			throw err;
		}

		if (aoflToken) {
			setLocalStorageItem(LOCAL_STORAGE_KEYS.AOFL_TOKEN, `${aoflToken}`);
		}

		Sentry.addBreadcrumb({
			category: 'api',
			message: 'API Post-Request Success',
			level: 'info',
			data: {
				...json.payload
			}
		});

		return json.payload;
	}

	/**
	 * Logs events to the server.
	 * @param {{ event: object }} events - The events to be logged.
	 * @returns {Promise<object>} A promise that resolves when the request is complete.
	 */
	static eventLog({ event }) {
		const request = {
			events: [event]
		};
		return ApiService.request('/ws/amsl/0.1/json/Event/Log/init', request);
	}

	/**
	 * Creates a device code for TV clients
	 * @returns {Promise<object>} - A promise that resolves with the response from the API.
	 */
	static createDeviceCode() {
		const request = {
			aofl_product_uuid: AOFL_PRODUCT_UUIDS.ABCMOUSE
		};

		return ApiService.request('/ws/amsl/0.1/json/DeviceCode/Create/init', request);
	}

	/**
	 * Checks if the given device code has an active session.
	 * @param {{ deviceCode: string; }} payload - The payload containing TV device code.
	 * @returns {Promise<object>} - A promise that resolves with the response from the API.
	 */
	static validateDeviceCodeSession(payload) {
		const request = {
			aofl_product_uuid: AOFL_PRODUCT_UUIDS.ABCMOUSE,
			device_code: payload?.deviceCode ?? ''
		};

		return ApiService.request('/ws/amsl/0.1/json/DeviceCode/VerifyDeviceSession/init', request);
	}

	/**
	 * Checks if a given device code is valid.
	 * @param {{ deviceCode: string; }} payload - The payload containing TV device code.
	 * @returns {Promise<object>} - A promise that resolves with the response from the API.
	 */
	static validateDeviceCode(payload) {
		const request = {
			aofl_product_uuid: AOFL_PRODUCT_UUIDS.ABCMOUSE,
			device_code: payload?.deviceCode ?? ''
		};

		return ApiService.request('/ws/amsl/0.1/json/DeviceCode/Validate/init', request);
	}

	/**
	 * Resolves pixel information for a given payload.
	 * @param {{ deviceCode: string; token: string }} payload - The payload containing TV device code and session token.
	 * @returns {Promise<object>} - A promise that resolves with the response from the API.
	 */
	static linkDeviceCodeWithSession(payload) {
		const request = {
			aofl_product_uuid: AOFL_PRODUCT_UUIDS.ABCMOUSE,
			device_code: payload?.deviceCode,
			request_token: payload?.token
		};

		return ApiService.request('/ws/amsl/0.1/json/DeviceCode/AssociateUserSession/init', request);
	}

	/**
	 * Resolves pixel information for a given payload.
	 * @param {{ event: string; campaignInfo: object; }} payload - The payload containing event and campaign information.
	 * @returns {Promise<object>} - A promise that resolves with the response from the API.
	 */
	static resolvePixelInfo(payload) {
		const request = {
			product_uuid: AOFL_PRODUCT_UUIDS.ABCMOUSE,
			event: payload.event,
			campaign_info: payload.campaignInfo
		};

		return ApiService.request('/ws/amsl/0.1/json/Campaign/ResolvePixelInfo/init', request);
	}

	/**
	 * Performs payment pre-processing.
	 * @param {{ payment_provider: PAYMENT_TYPE; currency_code: string; payment_provider_info: object; }} payload - The payment provider.
	 * @returns {Promise<any>} A promise that resolves with the result of the payment pre-processing.
	 */
	static paymentPreProcess({ payment_provider, currency_code, payment_provider_info }) {
		const request = {
			aofl_product_uuid: AOFL_PRODUCT_UUIDS.ABCMOUSE,
			payment_provider,
			currency_code,
			payment_provider_info
		};
		return ApiService.request('/ws/amsl/0.1/json/Payment/PreProcess/init', request);
	}

	/**
	 * Performs a auxiliary product elibility check.
	 * @param {{ auxiliaryProductRole: string; userToProductUuid: string; }} payload - the role for which to check eligibility
	 * @returns {Promise<any>} A promise that resolves with the result of the eligibility check
	 */
	static getAuxiliaryProductEligiblity({ auxiliaryProductRole, userToProductUuid }) {
		const request = {
			aofl_product_uuid: AOFL_PRODUCT_UUIDS.ABCMOUSE,
			user_to_product_uuid: userToProductUuid,
			role: auxiliaryProductRole
		};
		return ApiService.request('/ws/amsl/0.1/json/Subscription/AuxiliaryEligibility/init', request);
	}

	/**
	 * Performs a cross product eligibility check
	 * @param {{ cross_product_aofl_product_uuid: string; }} payload - The payload to check eligibility
	 * @returns {Promise<any>} A promise that resolves with the result of the cross product purchase
	 */
	static crossProductCheckEligibility({ cross_product_aofl_product_uuid }) {
		const request = {
			cross_product_aofl_product_uuid: cross_product_aofl_product_uuid
		};
		return ApiService.request('/ws/amsl/0.1/json/CrossProduct/CheckEligibility/init', request);
	}

	/**
	 * Performs a cross product purchase
	 * @param {{ subscription_hash: string; cross_product_aofl_product_uuid: string; origin: string; password: string; }}  payload - The payload to purchase cross product.
	 * @returns {Promise<any>} A promise that resolves with the result of the cross product purchase.
	 */
	static crossProductSignUp({
		subscription_hash,
		cross_product_aofl_product_uuid,
		origin,
		password
	}) {
		const request = {
			cross_product_aofl_product_uuid,
			cross_product_hash: subscription_hash,
			origin,
			password
		};
		return ApiService.request('/ws/amsl/0.1/json/CrossProduct/SignUp/init', request);
	}

	/**
	 * Registers a prospect's email.
	 * @param {object} options - The options for email registration.
	 * @param {string} options.email_address - The email address to register.
	 * @param {string} options.prospect_type - The type of prospect.
	 * @param {string} options.language_locale - The language locale.
	 * @param {string} options.campaign_source_tag - The campaign source tag.
	 * @param {string} options.registration_type - The registration type.
	 * @param {string} options.subscription_status - The subscription status.
	 * @returns {Promise<any>} A promise that resolves with the registration response.
	 */
	static prospectRegisterEmail({
		email_address,
		prospect_type,
		language_locale,
		campaign_source_tag,
		registration_type,
		subscription_status
	}) {
		const request = {
			aofl_product_uuid: AOFL_PRODUCT_UUIDS.ABCMOUSE,
			email_address,
			prospect_type,
			language_locale,
			campaign_source_tag,
			registration_type,
			subscription_status
		};
		return ApiService.request('/ws/amsl/0.1/json/Prospect/RegisterEmail/init', request);
	}

	/**
	 * Retrieves the completed step using the provided parameters.
	 * @param {{completed_step: string;}} completed_step - The completed step object.
	 * @returns {Promise<object>} - A promise that resolves with the completed step data.
	 */
	static postCompletedStep({ completed_step }) {
		const request = {
			completed_step
		};
		return ApiService.request('/ws/amsl/0.1/json/RegistrationPath/GetCompletedStep/init', request);
	}

	/**
	 * // Possible soln. use a new key-step that is driven by reg-path config.
	 * Sends an order confirmation email to the user.
	 * @returns {Promise<object>} - A promise that resolves when the order confirmation request is completed.
	 */
	static regPathComplete() {
		const request = {};
		return this.request('/ws/amsl/0.1/json/RegistrationPath/Complete/init', request);
	}

	/**
	 * Retrieves subscription information for a subscriber.
	 * @returns {Promise<object>} A promise that resolves with the subscription information.
	 */
	static getSubscriptionInfo() {
		const request = {};
		return ApiService.request('/ws/amsl/0.1/json/Subscriber/GetSubscriptionInfo/init', request);
	}

	/**
	 * Retrieves subscription information for a subscriber.
	 * @returns {Promise<object>} A promise that resolves with the subscription information.
	 */
	static getSubscriptions() {
		const request = {};
		return ApiService.request('/ws/amsl/0.1/json/Subscriber/GetSubscriptions/init', request);
	}

	/**
	 * Pre-processes a subscription for an existing user: reactivation or additional product purchase.
	 * @param {object} payload payload
	 * @returns {Promise<object>}
	 */
	static preProcessSubscription(payload) {
		const payloadWithProductId = {
			aofl_product_uuid: AOFL_PRODUCT_UUIDS.ABCMOUSE,
			...payload
		};
		return ApiService.request(
			'/ws/amsl/0.1/json/Subscription/PreProcessCreate/init',
			payloadWithProductId
		);
	}

	/**
	 * Pre-processes the subscription for a new user.
	 * @param {object} payload payload
	 * @returns {Promise<object>}
	 */
	static preProcessSubscriptionAndUser(payload) {
		const payloadWithProductId = {
			aofl_product_uuid: AOFL_PRODUCT_UUIDS.ABCMOUSE,
			...payload
		};
		return ApiService.request(
			'/ws/amsl/0.1/json/Subscription/PreProcessSignup/init',
			payloadWithProductId
		);
	}

	/**
	 * Validates and gets meta data for a code.
	 * @param {object} payload payload
	 * @returns {Promise<object>}
	 */
	static validateCode(payload) {
		const payloadWithProductId = {
			aofl_product_uuid: AOFL_PRODUCT_UUIDS.ABCMOUSE,
			...payload
		};
		return ApiService.request('/ws/amsl/0.1/json/Item/Validate/init', payloadWithProductId);
	}

	/**
	 * Creates (signs-up) a user and creates a subscription.
	 * @param {object} payload payload
	 * @returns {Promise<object>}
	 */
	static createSubscriptionAndUser(payload) {
		return ApiService.request('/ws/amsl/0.1/json/Subscription/SignUp/init', payload);
	}

	/**
	 * Creates a subscription for an existing user: reactivation or additional product purchase.
	 * @param {object} payload payload
	 * @returns {Promise<object>}
	 */
	static createSubscription(payload) {
		return ApiService.request('/ws/amsl/0.1/json/Subscription/Create/init', payload);
	}

	/**
	 * Checks if a user is active.
	 * @param {object} options - The options for checking user activity.
	 * @param {string} options.username - The username.
	 * @param {string} options.password - The password.
	 * @param {string} options.country_abbrv - The country abbreviation.
	 * @param {string} options.username_type - The type of username.
	 * @returns {Promise<any>} A promise that resolves with the result of the user activity check.
	 */
	static userGetStatus(options) {
		const request = {
			aofl_product_uuid: AOFL_PRODUCT_UUIDS.ABCMOUSE,
			credential: options
		};
		return ApiService.request('/ws/amsl/0.1/json/User/GetStatus/init', request);
	}

	/**
	 * Checks if a user is legacy or shared services.
	 * @param {string} token - Session token.
	 * @returns {Promise<any>} A promise that resolves with the result of the user type check.
	 */
	static userGetInfo(token) {
		const request = {
			aofl_product_uuid: AOFL_PRODUCT_UUIDS.ABCMOUSE,
			token
		};
		return ApiService.request('/ws/amsl/0.1/json/User/GetInfo/init', request);
	}

	/**
	 * Checks if a user is legacy or shared services.
	 * @param {string} token - Session token.
	 * @returns {Promise<any>} A promise that resolves with the result of the user type check.
	 */
	static userGetType(token) {
		const request = {
			aofl_product_uuid: AOFL_PRODUCT_UUIDS.ABCMOUSE,
			token
		};
		return ApiService.request('/ws/amsl/0.1/json/User/GetType/init', request);
	}

	/**
	 * Upgrades the subscription plan.
	 * @param {object} options - The hash of the subscription plan.
	 * @param {string} options.subscription_plan_hash - The hash of the subscription plan.
	 * @param {string} options.action_source - The source of the action.
	 * @param {object} options.payment_provider_info - Payment info
	 * @returns {Promise<object>} - A promise that resolves with the result of the upgrade request.
	 */
	static subscriptionUpgrade({ subscription_plan_hash, action_source, payment_provider_info }) {
		const request = {
			subscription_plan_hash,
			action_source,
			payment_provider_info
		};
		return ApiService.request('/ws/amsl/0.1/json/Subscription/Upgrade/init', request);
	}

	/**
	 * Retrieves the upgrade eligibility information.
	 * @returns {Promise<object>} A promise that resolves with the upgrade eligibility information.
	 */
	static getUpgradeEligibility() {
		const request = {};
		return ApiService.request('/ws/amsl/0.1/json/Subscription/UpgradeEligibility/init', request);
	}

	// 	/**
	// 	 * Sends a request to initiate the password reset process for a user.
	// 	 * @param {string} username_type - The type of username (e.g., email, username).
	// 	 * @param {string} username - The username of the user.
	// 	 * @param {string} country_abbrv - The abbreviation of the country.
	// 	 * @returns {Promise} A promise that resolves with the response from the server.
	// 	 */
	// 	static userForgotPassword({ username_type, username, country_abbrv }) {
	// 		const request = {
	// 			aofl_product_uuid: AOFL_PRODUCT_UUIDS.ABCMOUSE,
	// 			username_type,
	// 			username,
	// 			country_abbrv
	// 		};
	// 		return ApiService.request('/ws/amsl/0.1/json/User/ForgotPassword/init', request);
	// 	}

	/**
	 * Logs in a user with the provided credentials.
	 * @param {object} credential - The credential object.
	 * @param {string} credential.username - The username.
	 * @param {string} credential.password - The password.
	 * @param {string} credential.country_abbrv - The country abbreviation.
	 * @param {string} credential.username_type - The type of username.
	 * @returns {Promise<any>} A promise that resolves with the login response.
	 */
	static login(credential) {
		const request = {
			credential,
			product_uuid: AOFL_PRODUCT_UUIDS.ABCMOUSE
		};
		return ApiService.request('/ws/amsl/0.1/json/User/Login/init', request);
	}

	/**
	 * Logs out the user.
	 * @returns {Promise<object>} A promise that resolves when the user is successfully logged out.
	 */
	static userLogout() {
		const request = {};
		return ApiService.request('/ws/amsl/0.1/json/User/Logout/init', request);
	}

	/**
	 * Resets the user's password.
	 * @param {object} credentialInfo - The credential object.
	 * @param {string} credentialInfo.token - The reset password token.
	 * @param {string} credentialInfo.email - The user's email.
	 * @param {string} credentialInfo.password - The new password.
	 * @param {string} credentialInfo.countryAbbrv - The country abbreviation.
	 * @param {string} credentialInfo.usernameType - The type of username.
	 * @returns {Promise} A promise that resolves with the result of the password reset request.
	 */
	static userResetPassword(credentialInfo) {
		const request = {
			token: credentialInfo.token,
			aofl_product_uuid: AOFL_PRODUCT_UUIDS.ABCMOUSE,
			credential: {
				username: credentialInfo.email,
				password: credentialInfo.password,
				country_abbrv: credentialInfo.countryAbbrv,
				username_type: credentialInfo.usernameType
			}
		};
		return ApiService.request('/ws/amsl/0.1/json/User/ResetPassword/init', request);
	}

	/**
	 * Validates the login.
	 * @returns {Promise<object>} A promise that resolves with the result of the login validation.
	 */
	static validateLogin() {
		const request = {};
		return ApiService.request('/ws/amsl/0.1/json/User/ValidateLogin/init', request);
	}

	/**
	 * Event log old system
	 * @param payload
	 * @returns {Promise<object>} A promise that resolves with the result of the login validation.
	 */
	static eventLogOSU(payload) {
		const request = [[payload]];
		return ApiService.request('/ws/msl/0.2/json/Event/Log/init', request);
	}

	/**
	 * Validates the login.
	 * @param {{token: string; plog: string;}} payload - payload
	 * @returns {Promise<object>} A promise that resolves with the result of the login validation.
	 */
	static validateLoginOSU({ token, plog }) {
		const request = [
			{
				token,
				plog
			}
		];
		return ApiService.request('/ws/msl/0.2/json/User/ValidateLogin/init', request);
	}

	/**
	 * Creates session in legacy (used for ABCm product) for interchangeable sessions.
	 * @param {{ token: string; }} payload - payload for the call.
	 * @returns {Promise<object>} A promise that resolves with the result of the login validation.
	 */
	static createSessionsInLegacy(payload) {
		const token = payload.token;
		const request = [
			{
				credential: {
					token
				}
			}
		];
		return ApiService.request('/apis/mws/0.3/json/Account/Session/init', request);
	}

	/**
	 * Retrieves the subscription information from a third-party provider.
	 * @param {{platformType: PAYMENT_TYPE}} payload - The payment provider.
	 * @returns {Promise<object>} A promise that resolves with the plan information.
	 */
	static getThirdPartyProviderPlans({ platformType }) {
		const request = {
			aofl_product_uuid: AOFL_PRODUCT_UUIDS.ABCMOUSE,
			payment_provider: platformType
		};
		return ApiService.request('/ws/amsl/0.1/json/ThirdPartyProvider/GetPlans/init', request);
	}

	/**
	 * Retrieves the existing subscriptions for a third-party provider based on a receipt.
	 * @param {object} payload - The payload .
	 * @param {string} payload.payment_provider - The payment provider.
	 * @param {object} payload.payment_provider_info - The payment provider information.
	 * @returns {Promise<object|any>} A promise that resolves with the subscription information.
	 */
	static getThirdPartyProviderSubscriptions({ payment_provider, payment_provider_info }) {
		const request = {
			aofl_product_uuid: AOFL_PRODUCT_UUIDS.ABCMOUSE,
			payment_provider,
			payment_provider_info
		};
		return ApiService.request(
			'/ws/amsl/0.1/json/ThirdPartyProvider/GetSubscriptions/init',
			request
		);
	}

	/**
	 * Retrieves special offer template data.
	 * @param {string} offerHash - The URL slug that identifies the special offer
	 * @returns {Promise<object>} A promise that resolves with the offer template data.
	 */
	static getSpecialOfferTemplate(offerHash) {
		const request = {
			url: offerHash
		};
		return ApiService.request('/ws/amsl/0.1/json/SpecialOffer/Get/init', request);
	}

	/**
	 * Sets the URL of the special offer that the user purchased in the user_meta_data table.
	 * @param {string} offerHash - The URL slug that identifies the special offer
	 * @returns {Promise<object>}
	 */
	static setSpecialOffer(offerHash) {
		const request = {
			special_offer: offerHash
		};
		return ApiService.request('/ws/amsl/0.1/json/RegistrationPath/SetSpecialOffer/init', request);
	}

	/**
	 * Terminates an Account (For Admin only).
	 * @returns {Promise<any>} A promise that resolves with the termination result.
	 */
	static terminateSubscriptionAdmin() {
		const request = {
			args: {}
		};
		return ApiService.request('/ws/amsl/0.1/json/Subscription/Terminate/init', request);
	}
	/**
	 * Retrieves the existing subscription for a third-party provider based on a receipt.
	 * @param {object} payload - The payload .
	 * @param {string} payload.payment_provider - The payment provider.
	 * @param {object} payload.payment_provider_info - The payment provider information.
	 * @returns {Promise<object>} A promise that resolves with the subscription information.
	 */
	static getThirdPartyProviderSubscription({ payment_provider, payment_provider_info }) {
		const request = {
			aofl_product_uuid: AOFL_PRODUCT_UUIDS.ABCMOUSE,
			payment_provider,
			payment_provider_info
		};
		return ApiService.request('/ws/amsl/0.1/json/ThirdPartyProvider/GetSubscription/init', request);
	}
	/**
	 * Process item purchase.
	 * @param {object} payload payload
	 * @returns {Promise<object>}
	 */
	static itemPurchase(payload) {
		const payloadWithProductId = {
			aofl_product_uuid: AOFL_PRODUCT_UUIDS.ABCMOUSE,
			...payload
		};
		return ApiService.request('/ws/amsl/0.1/json/Item/Purchase/init', payloadWithProductId);
	}

	/**
	 * Updates email unsubscribe preferences for user.
	 * @param {object} payload payload
	 * @param {string} payload.email_address - The user's email address.
	 * @param {string} payload.subscription_status - The new subscription status.
	 * @returns {Promise<object>}
	 */
	static receipientUpdateStatus({ email_address, subscription_status }) {
		const payload = {
			email_address,
			subscription_status
		};
		return ApiService.request('/ws/amsl/0.1/json/Recipient/UpdateStatus/init', payload);
	}
}

export { ApiService };
